import React from 'react'
import { Link } from "gatsby"
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import Helmet from 'react-helmet'
import { Container, Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input } from 'reactstrap'
import styled from 'styled-components'
import Scroll from 'react-scroll'
import Accordion from '../../components/Accordion'
import NotificationSystem from 'react-notification-system'
import Layout from '../../components/Layout'

const CheckText = styled.div`
  position: relative; padding-left: 50px;padding-bottom:15px;line-height:1.8rem;font-size:1.2rem;
  &:before {
    position:absolute;
    color: #ffc107;
    left: 0;
    top: 5px;
    font-family: 'Font Awesome\\ 5 Free';
    font-weight: 900; display: inline-block; line-height: 1; font-style: normal;
    font-size:24px;
    content: '\\F00C';
  }
`
const SendResumeButton = styled(Button)`
  background-color: #1C838F !important;
  &:hover {
    background-color: #166e77 !important;
  }
`

export default class extends React.Component {
  notificationSystem = null;

  constructor(props) {
    super(props)
    this.state = {
      modal: false,
      resumeUploadLoading: false,
      name: '', email: '', phone: '', message: '', resume: null
    }
  }

  componentDidMount() {
    this.notificationSystem = this.refs.notificationSystem
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    })
  }

  handleSubmit = e => {
    this.setState({ resumeUploadLoading: true })
    let data = new FormData()
    data.append('name', this.state.name)
    data.append('email', this.state.email)
    data.append('phone', this.state.phone)
    data.append('message', this.state.message)
    data.append('resume', this.state.resume)
    data.append('form-name', 'resume')

    fetch('/', {
      method: 'POST',
      body: data
    }).then(() => {
      setTimeout(() => {
        this.notificationSystem.addNotification({
          title: 'We Got It!',
          message: 'Thank you for submitting your resume. We will be in touch!',
          level: 'success',
          autoDismiss: 0,
          position: 'bc'
        })
        this.setState({
          modal: false,
          resumeUploadLoading: false
        })
      }, 2000)
    }).catch((err) => {
      this.notificationSystem.addNotification({
        title: 'Error',
        message: 'There was a problem submitting your resume. Please contact us for details',
        level: 'error',
        autoDismiss: 0,
        position: 'bc'
      })
      this.setState({ resumeUploadLoading: false })
    })

    e.preventDefault()
  }

  handleChange = e => this.setState({ [e.target.name]: e.target.value });
  handleFileChange = e => {
    this.setState({ resume: e.target.files[0] })
    console.log(this.state)
  }

  render() {
    return (
      <Layout>
    <div className="about-us">
      <Helmet
        title="Careers | Bishop Software"
        meta={[
          { name: 'description', content: 'Find a software development career that unleashes your creativity. At Bishopsoft, everyone is a leader.' },
          { name: 'keywords', content: 'careers,career,job,developer,software,development,web,design,mobile,consultancy,consulting,website,application,react,ios,android' }
        ]}
      />
      <section style={{paddingTop: '75px', display: 'block', backgroundImage: 'linear-gradient(132deg, rgba(3,57,115,1) 0%, rgba(44,136,148,1) 73%, rgba(26,174,196,1) 90%, rgba(33,225,255,1) 100%)', color: '#fafafa'}}>
        <Header dropdownGradient='linear-gradient(#24a4bb 0px, rgb(20, 80, 142) 100%)' section="about-us" />
        <Container className="text-center" style={{paddingTop: '1.875rem'}}>
          <div style={{margin: '55px 0 0 0'}}>
            <h1 style={{color: '#ffc107', fontSize: '40px', fontWeight: 300}}>
              Unleash Your Talent with Bishop Software
            </h1>
            <h3 style={{fontSize: '18px', marginTop: '35px', fontWeight: 400, lineHeight: '24px'}}>The key to the success of our company is a reliable and united team of like-minded people. We seek to uncover the potential and strong sides of each team member, and, of course, unleash employees’ creativity in full. Bishop Software takes care of every employee and listens attentively to every new idea and proposal.</h3>
            <div style={{marginTop: '35px', paddingBottom: '35px'}}>
              <Scroll.Link to="contact" spy={true} smooth={true} className="round btn btn-primary"><i className="fas fa-arrow-down"></i></Scroll.Link>
            </div>

            
          </div>
        </Container>
      </section>

    <Scroll.Element name="contact" />
  <section style={{padding: '70px 0', display: 'block', backgroundImage: 'linear-gradient(176deg, #fff 0, #efefef 90%, #eee 100%)'}}>
    <Container>
      <Row>
        <Col xs="12" md="6">
          <div style={{textAlign: 'center', borderBottom: '1px dashed #ccc', marginBottom: '20px'}}>
            <h2 style={{color: '#21738B'}}>For Juniors</h2>
          </div>
          <div style={{paddingLeft: '25px'}}>
            <CheckText>Ongoing professional growth</CheckText>
            <CheckText>A chance to work on interesting projects (own products and outsourcing projects)</CheckText>
            <CheckText>Flexible working hours (you can combine work and study)</CheckText>
            <CheckText>Internal corporate training (JavaScript, Node.js) and working under mentor lead</CheckText>
            <CheckText>Different benefits (health insurance, corporate events and more)</CheckText>
          </div>
        </Col>
        <Col xs="12" md="6">
          <div style={{textAlign: 'center', borderBottom: '1px dashed #ccc', marginBottom: '20px'}}>
            <h2 style={{color: '#21738B'}}>For Experts</h2>
          </div>
          <div style={{paddingLeft: '25px'}}>
            <CheckText>A chance to work on interesting and challenging projects: both with own products, and outsourcing projects</CheckText>
            <CheckText>A chance to work with different frameworks and technologies</CheckText>
            <CheckText>Possibility of professional growth as a Technical Lead and Project Manager</CheckText>
            <CheckText>Different benefits (health insurance, corporate events and more)</CheckText>
          </div>
        </Col>
      </Row>
      
    </Container>
  </section>

  <section style={{backgroundImage: 'linear-gradient(175deg, #2d4f5f 0, #188d98 80%, #3eb8b6 100%)', padding: '70px 0'}}>
    <Container>
      <Row style={{textAlign: 'center', marginBottom: '50px'}}>
        <Col><h1 style={{color: '#ffc107', textAlign: 'center', width: '100%', paddingBottom: '10px', borderBottom: '1px dashed #ffc107', fontWeight: 300}}>Open Positions</h1></Col>
      </Row>
      <Row>
        <Col>
          <Accordion title="Junior Javascript Developer" icon="fab fa-js-square fa-3x">
            <h3 style={{color: '#1C838F',marginBottom: '15px'}}>Minimum Qualifications</h3>
            <ul>
              <li>No experience necessary</li>
              <li>Knowledge of JavaScript</li>
              <li>Knowledge of HTML, CSS</li>
              <li>Intermediate level of English</li>
            </ul>
            <h3 style={{color: '#1C838F',marginBottom: '15px'}}>Preferred Qualifications</h3>
            <ul>
              <li>Basic knowledge of React.js, Node.js, Angular.js</li>
              <li>Basic competencies in Node.js, PHP, .NET</li>
            </ul>
            <div style={{textAlign: 'center'}}>
              <SendResumeButton onClick={this.toggle}>Send Resume</SendResumeButton>
            </div>
          </Accordion>

          <Accordion title="Mid Javascript Developer" icon="fab fa-js-square fa-3x">
            <h3 style={{color: '#1C838F',marginBottom: '15px'}}>Minimum Qualifications</h3>
            <ul>
              <li>At least 2-3 years of experience in Javascript development</li>
              <li>Good knowledge of JavaScript, HTML CSS</li>
              <li>Experience on one of the following: React.js, Angular.js, Vue</li>
              <li>Knowledge of Javascript patterns</li>
              <li>Intermediate level of English</li>
            </ul>
            <h3 style={{color: '#1C838F',marginBottom: '15px'}}>Preferred Qualifications</h3>
            <ul>
              <li>Working experience with other JS frameworks</li>
              <li>Basic competencies in full-stack development (Node.js, PHP, .NET)</li>
            </ul>
            <div style={{textAlign: 'center'}}>
              <SendResumeButton onClick={this.toggle}>Send Resume</SendResumeButton>
            </div>
          </Accordion>

          <Accordion title="Senior Javascript Developer" icon="fab fa-js-square fa-3x">
            <h3 style={{color: '#1C838F',marginBottom: '15px'}}>Minimum Qualifications</h3>
            <ul>
              <li>More than 3 years of experience in Javascript development</li>
              <li>Deep skills in JavaScript, HTML CSS</li>
              <li>Experience on React.js, Angular.js, Vue</li>
              <li>Knowledge of modern technological tools</li>
              <li>Intermediate level of English</li>
            </ul>
            <h3 style={{color: '#1C838F',marginBottom: '15px'}}>Preferred Qualifications</h3>
            <ul>
              <li>Working experience with other JS frameworks</li>
              <li>Basic competencies in full-stack development (Node.js, PHP, .NET)</li>
            </ul>
            <div style={{textAlign: 'center'}}>
              <SendResumeButton onClick={this.toggle}>Send Resume</SendResumeButton>
            </div>
          </Accordion>
        </Col>
      </Row>
      
    </Container>
  </section>


        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>Apply for this job</ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup row>
                <Label sm="2">Name*</Label>
                <Col sm="10">
                  <Input type="text" name="name" value={this.state.name} onChange={this.handleChange} />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm="2">Email*</Label>
                <Col sm="10">
                <Input type="email" name="email" value={this.state.email} onChange={this.handleChange} />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm="2">Phone</Label>
                <Col sm="10">
                <Input type="text" name="phone" value={this.state.phone} onChange={this.handleChange} />
                </Col>
              </FormGroup>
              <FormGroup>
                <Input type="textarea" name="message" placeholder="Message..." rows="4" value={this.state.message} onChange={this.handleChange} />
              </FormGroup>
              <FormGroup row>
                <Label sm="2">Resume*</Label>
                <Col sm="10">
                  <Input type="file" name="resume" onChange={this.handleFileChange} />
                </Col>
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
      <SendResumeButton type="submit" onClick={this.handleSubmit} disabled={this.state.resumeUploadLoading}>Send{this.state.resumeUploadLoading && (<span> <i className="fas fa-spinner fa-pulse"></i></span>)}</SendResumeButton>
          </ModalFooter>
        </Modal>

      <Footer section="about-us" />

      <NotificationSystem ref="notificationSystem" style={{
        NotificationItem: {
          DefaultStyle: {
            fontSize: '16px', padding: '20px'
          }
        },
        Title: {
          DefaultStyle: {
            fontSize: '24px', margin: '0 0 10px 0'
          }
        },
        Dismiss: {
          DefaultStyle: {
            width: '20px', height: '20px', top: '7px', right: '8px', fontSize: '24px', lineHeight: '20px'
          }
        }
      }} />
    </div>
  </Layout>
    )
  }
}